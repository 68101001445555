<template functional>
  <v-card
    class="warehouse-card"
    :to="`/${props.warehouse.slug}`"
    :class="data.staticClass || ''"
  >
    <v-img
      min-height="140"
      :target="props.warehouse"
      :alt="props.warehouse.name"
      :src="parent.$ebsn.meta(props.warehouse, 'template_model.IMAGE.location')"
    >
      <div class="blurred-bg"></div>
      <v-card-actions class="justify-center">
        <v-btn color="primary" depressed class="overlay-btn">
          {{ props.warehouse.name }}
        </v-btn>
      </v-card-actions>
      <!-- <span class="overlay-label">
        {{ props.warehouse.name }}
      </span> -->
    </v-img>
  </v-card>
</template>
<style lang="scss">
.warehouse-card {
  .blurred-bg:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, transparent 65%, black 100%);
  }
  .overlay-label {
    color: var(--v-white-base);
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    position: absolute;
    bottom: 15px;
    width: 100%;
  }
  .overlay-btn {
    position: absolute;
    bottom: 15px;
    // width: 100%;
  }
}
</style>
<script>
export default {
  name: "WarehouseCard",
  props: {
    warehouse: { type: Object, required: true }
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>
