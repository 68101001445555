<template>
  <div class="warehouse-cooperative">
    <Breadcrumb :items="breadCrumbs" />

    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <v-container>
      <CategoryTitle :category="category" :addContainer="false" />

      <v-row
        class="cooperative-list"
        v-if="
          cooperativeList.length > 0 &&
            cooperativeCounterLoaded == cooperativeList.length
        "
      >
        <v-col
          cols="12"
          md="4"
          v-for="cooperative in cooperativeList"
          :key="cooperative.categoryId"
        >
          <WarehouseCard
            v-for="province in cooperative.children"
            :key="province.categoryId"
            class="warehouse-provice-card mb-6"
            :warehouse="province"
          />
        </v-col>
      </v-row>
    </v-container>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style lang="scss">
.warehouse-cooperative {
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import WarehouseCard from "@/components/store/WarehouseCard.vue";

import category from "~/mixins/category.js";
import { calculateBreadcrumbs } from "@/components/store/warehouseHelper";

import CategoryService from "~/service/categoryService";

export default {
  components: {
    Breadcrumb,
    CategoryTitle,
    WarehouseCard
  },
  name: "StoreLocator",
  mixins: [category],
  data() {
    return {
      cooperativeList: [],
      cooperativeCounterLoaded: 0
    };
  },
  computed: {
    breadCrumbs() {
      return calculateBreadcrumbs(this.category.slug);
    }
  },
  methods: {
    async fetchSingleCooperative(cooperativeSlug) {
      try {
        let res = await CategoryService.getCategoryBySlug(cooperativeSlug);
        if (res) {
          this.cooperativeList.push(res);
          console.log(this.cooperativeList);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.cooperativeCounterLoaded++;
      }
    },
    fetchCooperativeCategories() {
      if (
        this.category &&
        this.category.children &&
        this.category.children.length > 0
      ) {
        this.category.children.forEach(child => {
          this.fetchSingleCooperative(child.slug);
        });
      }
    }
  },
  mounted() {
    this.fetchCooperativeCategories();
  }
};
</script>
